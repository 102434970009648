import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

import "../scss/work.scss"
import Moment from "moment"

const ProjectsPage = () => {
  // Static query
  const data = useStaticQuery(graphql`
    query WorkIndexQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            id
            frontmatter {
              path
              title
              type
              image {
                childImageSharp {
                  fluid(maxWidth: 950) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              date
              author
              tags
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {data.allMarkdownRemark.edges.map(project => {
        if (project.node.frontmatter.tags[0] === "featured") {
          return (
            <Link
              to={project.node.frontmatter.path}
              key={project.node.id}
              className="project_preview"
            >
              <Img
                fluid={project.node.frontmatter.image.childImageSharp.fluid}
                className="project_preview_image"
              />
              <div className="project_preview_info">
                <div className="type_and_date">
                  <div className="project_preview_type">
                    {project.node.frontmatter.type}
                  </div>

                  <div className="project_preview_date">
                    {project.node.frontmatter.date === "Ongoing" ? (
                      <>Ongoing</>
                    ) : (
                      Moment(project.node.frontmatter.date).format("YYYY")
                    )}
                  </div>
                </div>

                <div className="project_preview_title">
                  {project.node.frontmatter.title}
                </div>
              </div>
            </Link>
          )
        } else {
          return null
        }
      })}
    </>
  )
}

/*export const query = graphql`
  query WorkIndexQuery {
    allMarkdownRemark {
    edges {
    node {
    id
    frontmatter {
    path
    title
    date
    author
  }
  }
  }
  }
  }
  `*/

export default ProjectsPage
